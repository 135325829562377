<template>
  <div class="complaint-page">
    <div class="content">
      <h1 class="header">用户服务协议</h1>
      <p>欢迎使用本网站网盘搜索引擎！</p>
      <p>
        请务必认真阅读和理解本《用户服务协议》（以下简称“协议”）
        中规定的所有权利和限制。您一旦使用本站所有网络服务等行为将视为对本《协议》的接受，即表示您同意接受本《协议》各项条款的约束。如果您不同意本《协议》中的条款，您应放弃使用本网站。
      </p>
      <h3>一、服务内容及使用须知</h3>
      <ol>
        <li>
          本网站基于互联网行业普遍认同的“蜘蛛”（Spider）抓取数据而运作，即本网站的蜘蛛将从某些网页出发，通过网页间的相互链接关系，对互联网上的超链接进行访问和收集。由于互联网上的超链接数以亿计，本网站可能无法发现和收集全部链接，而是根据链接的特点选择其中的一部分。
        </li>
        <li>
          本网站将根据您在搜索框中键入的关键词及发出的搜索指令，根据独有的算法，为您生成搜索结果，搜索结果的内容为若干条和关键词相关的第三方网页链接。
        </li>
        <li>
          本网站会以非人工检索方式自动生成到第三方网页的链接，本网站不存储、控制或修改该等第三方网页中的内容，且不对该等第三方网页或资源的可用性负责，亦不对该等网络或资源上的或从该等网站或资源获得的任何广告、产品或其他材料加以认可。
        </li>
        <li class="em">
          本网站承诺尊重和保护权利人的知识产权。如果本网站提供的网页被权利人书面告知涉嫌侵犯其权益的，本网站有权根据中国法律法规和政府规范性文件采取措施移除前述内容或前述相关链接，而无须承担任何责任。
        </li>
        <li class="em">
          你不得滥用本网站服务，且同意本网站不属于对百度网盘等网盘资源分享链接进行选择、编辑和推荐，非通过售卖网站内部会员获取直接经济收益性质的网站。
        </li>
        <li class="em">
          你有义务和接受：若您发现搜索到的爬虫内容涉及到侵权内容，请予第一时间通过本站发送到电子邮件：shakanamo945@gmail.com，通知本站管理人员。
        </li>
        <li class="em">
          你认同：根据《信息网络传播权保护条例》规定权利人认为网络服务提供者服务所涉及的作品，侵犯自己的信息网络传播权的，可以向该网络服务提供者提交书面通知，要求网络服务提供者删除该作品，或者断开与该作品的链接。网络服务提供者接到通知后及时采取措施的，不承担赔偿责任。
        </li>
        <li class="em">
          你同意不以版权诉讼为目的来使用本网站服务，而仅用于学习且不二次分享。如你以版权诉讼或其他目的使用本网站服务，你同意对本站予以免责。
        </li>
        <li>
          根据用户使用本网站情况，我们将不断改善产品体验，例如提供个性化的服务或提高用户使用服务的安全性。同时，如经由通知、举报等
          途径发现用户在使用本网站服务所提供的网络服务时违反任何本协议的规定，我们有权要求用户改正或直接采取一切认为必要的措施（包括但不限于更改或删除用户账号、暂停或终止用户使用网络服务，和/或公示违法或违反本协议约定使用本网站服务用户账户的权利）以减轻用户不当行为造成的影响。
        </li>
      </ol>
      <h3>二、服务准则</h3>
      <ol>
        <li>
          本网站会根据业务及技术的发展情况，随时变换、调整所提供的网络服务的具体内容。除非另有其它明示规定，本网站所推出的新产品、新功能、新服务，均受到本协议规范。
        </li>
        <li>
          本网站服务仅供用户个人用于非商业用途。如果要将与本网站服务有关或派生的任何资料、服务或软件用于销售或其它商业用途，则必须得到本网站的书面许可。
        </li>
        <li>
          <p>用户在使用本网站网络服务过程中，必须遵循以下原则：</p>
          <ol>
            <li>遵守中国有关的法律和法规；</li>
            <li>遵守所有与网络服务有关的网络协议、规定和程序；</li>
            <li>不得为任何非法目的而使用网络服务系统；</li>
            <li>
              不得利用本网站网络进行任何可能对互联网或移动网正常运转造成不利影响的行为；
            </li>
            <li>
              不得侵犯其他任何第三方的专利权、著作权、商标权、名誉权或其他任何合法权益；
            </li>
            <li>不得利用本网站网络服务系统进行任何不利于本网站的行为；</li>
          </ol>
        </li>
        <li>
          如用户在使用本网站网络服务时违反任何上述规定，本网站或其授权的人有权要求用户改正或直接采取一切必要的措施以减轻用户不当行为造成的影响。
        </li>
        <li>
          用户如果对本网站服务有任何意见，或有如何改进的建议，可向本网站提出。
        </li>
      </ol>
      <h3>三、服务变更、中断或终止</h3>
      <ol>
        <li>
          本网站不断创新以向您提供最优体验。用户同意本网站有权根据业务发展情况随时变更、中断或终止部分或全部的网络服务而无需通知用户，也无需对任何用户或任何第三方承担任何责任；
        </li>
        <li>
          用户理解，本网站需要定期或不定期地对提供网络服务的平台（如互联网网站、移动网络等）或相关的设备进行检修或者维护，如因此类情况而造成网络服务在合理时间内的中断，本网站无需为此承担任何责任，但本网站尽可能事先进行通告。
        </li>
        <li>
          如用户违反本协议中规定的服务准则，本网站有权随时中断或终止向用户提供本协议项下的网络服务而无需对用户或任何第三方承担任何责任；
        </li>
      </ol>
      <h3>四、免责与责任限制</h3>
      <ol>
        <li>
          本网站会以非人工检索方式自动生成到第三方网页的链接，本网站不存储、控制或修改该等第三方网页中的内容，且不对该等第三方网页或资源的可用性负责，亦不对该等网络或资源上的或从该等网站或资源获得的任何广告、产品或其他材料加以认可。
        </li>
        <li>
          用户理解并同意自行承担自主选择、使用服务的风险，并且对于因使用服务而对计算机系统造成的损坏或数据的丢失，本网站不承担责任。
        </li>
        <li>
          在适用法律允许的最大范围内，本网站明确表示不提供任何其他类型的保证，不论是明示的或默示的，包括但不限于适销性、适用性、可靠性、准确性、完整性、无病毒以及无错误的任何默示保证和责任。另外，在适用法律允许的最大范围内，本网站并不担保服务一定能满足您的要求，也不担保服务不会被修改、中断或终止，并且对服务的及时性、安全性、错误发生，以及信息是否能准确、及时、顺利的传送均不作任何担保。
        </li>
        <li>
          在适用法律允许的最大范围内，本网站不就因您使用服务引起的或与服务有关的任何意外的、非直接的、特殊的、或间接的损害或请求（包括但不限于因人身伤害、因隐私泄漏、因未能履行包括诚信或合理谨慎在内的任何责任、因过失和因任何其他金钱上的损失或其他损失而造成的损害赔偿）承担任何责任。
        </li>
        <li>
          本网站承诺尊重和保护权利人的知识产权。如果本网站提供的网页被权利人书面告知涉嫌侵犯其权益的，本网站有权根据中国法律法规和政府规范性文件采取措施移除前述内容或前述相关链接，而无须承担任何责任。
        </li>
        <li>
          用户需要了解本网站服务的通用免责条款，请参见“本网站免责声明”。
        </li>
        <li>
          用户违反本协议规定，对本网站造成损害的，
          本网站有权采取包括但不限于中断使用许可、停止提供服务、限制使用、法律追究等措施。
        </li>
      </ol>
      <h3>五、其他条款</h3>
      <ol>
        <li>
          如果本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，或违反任何适用的法律，则该条款被视为删除，但本协议的其余条款仍应有效并且有约束力。
        </li>
        <li>
          本网站有权随时根据有关法律、法规的变化以及网站运营状况的调整等修改本协议。修改后的协议会在本网站上公布。当发生有关争议时，以最新的协议文本为准。如果不同意改动的内容，用户可以不选择使用本网站提供的服务。如果用户继续使用本网站提供的服务，则视为您接受本协议的变动。
        </li>
        <li>本网站在法律允许的最大范围内对本协议拥有解释权与修改权。</li>
      </ol>
      <div class="menu">
        <ul>
          <li><router-link to="/main/about/policy">免责声明</router-link></li>
          <li><router-link to="/main/about/agree">用户协议</router-link></li>
          <li><router-link to="/main/about/version">版权说明</router-link></li>
          <li><router-link to="/main/about/safe">净网声明</router-link></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_var.scss";
.complaint-page {
  min-height: 100%;
  background-color: #f8f8f8;
}
.content {
  margin: 20px auto;
  max-width: 800px;
  padding: 20px 40px;
  border-radius: 4px;
  border: 1px solid #ddd;
  background-color: #fff;
  font-size: 15px;
  line-height: 1.6;
  word-break: break-all;
  h3 {
    margin-top: 20px;
  }
  ol {
    list-style: decimal;
    padding-left: 30px;
    ol {
      list-style: lower-alpha;
    }
    li {
      margin-top: 10px;
    }
  }
  .header {
    margin-bottom: 10px;
    text-align: center;
  }
  .menu {
    width: 100%;
    border-top: 1px solid $theme-color;
    height: 40px;
    margin-top: 50px;
    ul {
      margin: 0px;
      list-style: none;
      li {
        float: right;
        margin-left: 30px;
        a {
          color: $theme-color;
        }
      }
    }
  }
}
@media (max-width: 700px) {
  .content {
    padding: 20px;
  }
}
</style>
